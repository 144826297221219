<template>
  <div class="visualTraining">
    <iframe  ref="prints" src='http://www.visumall.com/Visumallhdp/Index2.html' frameborder="0">
    </iframe>
  </div>
</template>
<script>
export default {
  name: "visualTraining",
  data() {
    return {
      titleIdx: 0
    }
  },
  created() {
    // console.log(this.$route.query.proId);
  }
}
</script>
<style scoped lang="scss">
.visualTraining {
  width: 100%;
  height: 100%;
}
iframe {
  width: 100%;
  height: 90%;
}
</style>
